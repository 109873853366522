import store from '@/store'
import api from './api'
import moment from 'moment'

const clienteProgramacionSpotService = {}




// Services para el controller clienteProgramacionSpot
// Ver por que no funciona un get con varios parametros (por ahora hice la petición por post cuando es un get)
clienteProgramacionSpotService.getProgramacionesByProg = function (codigoProgramacion, horaDesde) {
 return api.post('/clienteprogramacionspot/getSpots', { codigoProgramacion, horaDesde }).then((response)=>{
    const data = response.data
    return data
  })
}

// post para guardar un spot en el calendar
clienteProgramacionSpotService.guardarProgSpot = async function (spotsProg) {
  const resPromises = spotsProg.map(spoprog => api.post('/clienteprogramacionspot/', spoprog))
  const response = await Promise.all(resPromises)
  const result = response.filter(r => r.data)
  return result
}

// put para cambiar el modificar un spot en el calendar
clienteProgramacionSpotService.editarProgSpot = async function (codigo, codigoProgramacion, codigoSpot, numeroDia, horaDesde, horaHasta, orden) {
  return api.put('/clienteprogramacionspot/', {

    codigo, codigoProgramacion, codigoSpot, numeroDia, horaDesde, horaHasta, orden

  }).then(res => res.data)
}

clienteProgramacionSpotService.permutarProgSpot = async function (pdata) {
  return api.post('/clienteprogramacionspot/moverSpot', { ...pdata }).then(res => res.data)
}

clienteProgramacionSpotService.eliminarProgSpot = async function (codigoProgramacion, usuarioSpot) {
  return api.delete(`clienteprogramacionspot/${codigoProgramacion}/${usuarioSpot}`
  ).then(res => res.data)
}

clienteProgramacionSpotService.getProgSpotSucursal = async function (codigoProgramacion) {
  return api.get(`clienteprogramacionspot/sucursales/${codigoProgramacion}`
  ).then(res => res.data)
}

export default clienteProgramacionSpotService
