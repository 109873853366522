/* eslint-disable eqeqeq */
import api from './api'

const userService = {}

userService.login = async function (username, password) {
  return api.post('/Token/Login', {}, {
    auth: {
      username,
      password
    }
  })
}

userService.error = async function (statusCode) {
  return api.get(`/Admin/error/${statusCode}`, {
  }).then(res => res.data)
}

userService.logout = async function () {
  const refresh = localStorage.refresh_token
  const username = this.current().unique_name
  localStorage.clear()
  return api.put('/Token/Logout', {
    refresh, username
  }).then(res => location.reload())
}
userService.logoutRemoto = async function (username) {
  return api.get(`/ControlAire/LogoutRemoto/${username}`, {
    action: 'logout', username
  }).then(res => console.log(res))
}

userService.current = function () {
  if (typeof localStorage.user == 'undefined') return false
  return JSON.parse(localStorage.user)
}
userService.currentCliente = function () {
  if (typeof localStorage.user == 'undefined') return false
  return JSON.parse(JSON.parse(localStorage.user).Cliente)
}

userService.valirdarToken = function () {
  if (!localStorage.user) return false
  const user = this.current()
  user.exp = user.exp + '000'
  var fecha = Date.parse(Date())
  if (fecha > user.exp) {
    return false
  }
  return true
}

userService.autenticado = function () {
  if (this.valirdarToken()) {
    return true
  }

  return false
}

// userService.IsAdmin = function() {
//     let Cliente = JSON.parse(userService.current().Cliente);
//     if (Cliente !== null && typeof Cliente === 'object') {
//         return false;
//     }
//     return true;
// }

// userService.CambiarPass = async function(passAnterior, passNueva, passRepetir) {
//     return api.post('/Usuario/CambiarPass', { passAnterior, passNueva, passRepetir }).then(res => res.data);
// }

export default userService
