<template>
  <div class="is-max-widescreen">
      <top-navbar></top-navbar>
      <cliente-content></cliente-content>
      <!-- <content-footer></content-footer> -->
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import ClienteContent from './Content.vue'
import MobileMenu from './MobileMenu'
import MenuView from './MenuView'
import Reproductor from '../../components/Reproductor'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    ClienteContent,
    MobileMenu,
    MenuView,
    Reproductor
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}
</script>
