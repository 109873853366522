import Vue from 'vue'

import VueRouter from 'vue-router'
import RouterPrefetch from 'vue-router-prefetch'
import App from './App'
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import store from './store/index' // VUEX
import router from './router/index'

import i18n from './i18n'
import './registerServiceWorker'
import moment from 'moment'
import UUID from 'vue-uuid'
import 'buefy/dist/buefy.css'
import './assets/css/custom.css'
import Buefy from 'buefy'
Vue.prototype.$updateView = function (callHub, propertyName) {
  callHub.client.start()
  callHub.client.on('Receive', (response) => {
    this[propertyName] = JSON.parse(response)
  })
}
Vue.prototype.$loadingView = function () {
  this.$buefy.loading.open({
    container: null
  })
}
Vue.prototype.$isLoad = false
Vue.prototype.$isLoadFullPage = true
Vue.use(Buefy)
Vue.use(UUID)
Vue.use(moment)

Vue.use(VueRouter)
Vue.use(RouterPrefetch)

/* eslint-disable no-new */
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
