<template>
  <footer class="footer">
    <div class="container">
      <b-modal v-model="isImageModalActive">
        <router-view name="alta" />
      </b-modal>
    </div>
  </footer>
</template>
<script>
export default {
  data () {
    return {
      isImageModalActive: true,
      year: new Date().getFullYear(),
      texto_info_base: 'Administracion ClubF5'
    }
  },
  methods: {
  },
  mounted () {
    //this.getInfoBase()
  }
}
</script>
<style>
</style>
