import VueRouter from 'vue-router'
import CliRoutes from './clienteRoutes'

// GeneralViews

// dependientes del usuario
import NotFound from '@/pages/NotFoundPage.vue'
import LoginLayout from '@/layout/Login/LoginLayout.vue'
import DashboardLayoutCliente from '@/layout/cliente/ClienteLayout.vue'
import userservice from '../services/UserServices'
const Login = () => import(/* webpackChunkName: "dashboard" */'@/pages/Login.vue')

// eslint-disable-next-line no-unused-vars
var Layout = LoginLayout
var redirect = false
var Dashboard = () => (1)
switch (userservice.current()?.role) {
  case 'Cliente':
    Layout = DashboardLayoutCliente
    redirect = '/sucursales'
    Dashboard = () => import('@/pages/Dashboard.vue')
    break
  default:
    Layout = LoginLayout
    break
}

var routes = [
  {
    path: '/',
    component: Layout,
    redirect: redirect,
    children: [
      {
        path: '/sound',
        name: 'Inicio',
        components: Dashboard
      },
      {
        path: 'login',
        name: 'Iniciar Sesión',
        component: Login,
        meta: { Publico: true }
      }
      /** Ver como arreglar estas rutas */
    ]
  },
  { path: '*', component: NotFound }
]

// if (RoleUser === 'Cliente') {
routes[0].children = routes[0].children.concat(CliRoutes)
/* }

if (RoleUser === 'Reproductor') {
  routes[0].children = routes[0].children.concat(RepRoutes)
}

if (RoleUser === 'Operador') {
  routes[0].children = routes[0].children.concat(OpeRoutes)
} */
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.Publico && !userservice.autenticado()) {
    return next({ name: 'Iniciar Sesión' })
  } else if (to.meta.Publico && userservice.autenticado()) {
    return next({ name: 'Inicio' })
  }
  return next()
})

export default router
