<template>
  <div class="hero is-dark is-fullheight">
    <div class="hero-body ">
      <div class="container has-text-centered pt-5">
        <fade-transition>
          <router-view></router-view>
          
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
export default {
  components: {
    FadeTransition
  },
  
  methods: {

  },
  mounted () {

  }
}
</script>
