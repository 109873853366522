import store from '@/store'
import axios from 'axios'

// Configuración por defecto del objeto axios
// a que api nos conectamos para obtener datos

const url = `${process.env.VUE_APP_API_BASE_URL}/api`
//const url = `http://vps-3562447-x.dattaweb.com:3003/api`
// const url = 'https://api.laf5.com/api/'
// const url = 'https://api.clubf5.com/api/'
// const url = 'https://api.clubf5.com/api/'

const apiUrlFiles = 'https://api.laf5.com/Files'

const instance = axios.create({
  baseURL: url
})

instance.interceptors.request.use(
  (config) => {
    var token = localStorage.token
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.url = function () {
  return apiUrlFiles
}

export default instance
