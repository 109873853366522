<template>
  <b-navbar class="is-dark">
      <template #brand>
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img
                  src="@img/logo.png"
                  alt="Colocar aqui la imagen del cliente"
              >
          </b-navbar-item>
      </template>
      <template #start>
          <b-navbar-item tag="router-link" to="/sucursales">
             Reproductores
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/bibliotecaSpot">
             Biblioteca de spot
          </b-navbar-item>
          <!-- <b-navbar-dropdown label="Mis Spots">
              <b-navbar-item tag="router-link" to="/bibliotecaSpot">
                  Biblioteca de spots
              </b-navbar-item>
              <b-navbar-item href="#">
                  Programaci&oacute;n Spots
              </b-navbar-item>
          </b-navbar-dropdown> -->
      </template>

      <template #end>
          <b-navbar-item tag="div">
              <div class="buttons">
                  <a class="button is-primary" @click="Logout">
                      <strong>Salir</strong>
                  </a>
                  <!-- <a class="button is-light">
                      Log in
                  </a> -->
              </div>
          </b-navbar-item>
      </template>
  </b-navbar>
</template>
<script>
import UserServices from '../../services/UserServices'

export default {
  components: {
    
  },
  computed: {
  },
  data () {
    return {
      activeNotifications: false,
      showMenu: false,
      data: {
        notis: []
      },
      cantNotis: null
    }
  },
  methods: {

    Logout () {
      UserServices.logout().then(res => {
        window.location.href = ''
      })
    }
  }
}
</script>
