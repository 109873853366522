<template>
      <login-content></login-content>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import LoginContent from './Content.vue'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    LoginContent
  }
}
</script>
