import moment from 'moment'
import api from './api'
import ResumenSpotServices from './ResumenSpotServices'
const spotService = {}
// no le paso el codigo de cliente porque lo obtengo en la api
spotService.getSpotsBycodCliente = async function (viewvencidos = false) {
  return api.get('spot/Cliente/')
    // eslint-disable-next-line no-return-assign
    .then((res) => {
      console.log(res)
      const result = (viewvencidos) ? res.data.filter(spo => spo.spo_usuario === JSON.parse(localStorage.user).unique_name) : res.data.filter(spo => spo.spo_usuario === JSON.parse(localStorage.user).unique_name && spo.vencido >= 0)
      return result
        .map(spo => {
          return {
            codSpot: spo.spo_codigo,
            codCli: spo.spo_codcli,
            nombreSpot: spo.spo_nombre,
            tipo: spo.spo_tipo,
            fechaInicio: spo.spo_fecini,
            fechaFin: spo.spo_fecfin,
            source: spo.spo_source,
            usuario: spo.spo_usuario,
            estado: spo.spo_codigoColor,
            duracion: spo.spo_dursec,
            vencido: spo.vencido,
            slot: null
          }
        })
    })
}

spotService.getSpots = async function () {
  return api.get('/spot/')
    .then((res) => {
      return res.data
    }).then(res => {
      const result = res.filter(e => e.spo_usuario == JSON.parse(localStorage.user).unique_name)
      const list = [];
      result.forEach(async element => {
        const urlAudio = `${process.env.VUE_APP_OBS_ROOT_URL}${element.spo_source}`
        const audio = new Audio(urlAudio)
        audio.canPlayType('audio/ogg')
        audio.addEventListener('loadeddata', (ev) => {
          list.push({
            slot:null,
            spo_nombre: element.spo_nombre,
            spo_codigo: element.spo_codigo,
            spo_fecini: moment(element.spo_fecini).format('DD-MM-YYYY'),
            spo_fecfin: (element.spo_tipo == 'inst') ? 'No vence' : (element.vencido >= 0) ? moment(element.spo_fecfin).format('DD-MM-YYYY') : 'Vencido',
            spo_source: element.spo_source,
            spo_source_src: urlAudio,
            spo_codcli: element.spo_codcli,
            spo_tipo: element.spo_tipo,
            spo_dursec: ev.target.duration,
            vencido: (element.vencido < 0)
          })
        })
      })
      return list.sort((a, b) => b.spo_tipo - a.spo_tipo)
    })
}

spotService.guardarSpot = async function (formdata) {
  return api.post('/spot/', formdata
  ).then((res) => { localStorage.removeItem('spots') })
}

spotService.guardarSpotOperador = async function (nombreSpot, source, fechaDesde, fechaHasta, tipoSpot, cliente, clienteCodigo, locutor, codPedido) {
  return api.post('/spot/operador', { nombreSpot, source, fechaDesde, fechaHasta, tipoSpot, cliente, clienteCodigo, locutor, codPedido }
  ).then(res => res.data)
}

spotService.uploadSpot = async function (formData, tipo) {
  return api.post('/spot/upload', formData
  ).then(res => res.data)
}

spotService.uploadNoticias = async function (formData) {
  return api.post('/spot/uploadNoti', formData
  ).then(res => res.data)
}

spotService.uploadSpotOperador = async function (formData, idCliente) {
  return api.post(`/spot/upload/${idCliente}`, formData
  ).then(res => res.data)
}

spotService.cambiarVencimiento = async function (codigoSpot, fecha) {
  return api.put('/spot/', { codigoSpot, fecha }
  ).then(res => res.data)
}

spotService.editarSpot = async function (fromdata) {
  return api.put('/spot/editar', fromdata
  ).then(res => res.data)
}
spotService.editarNoti = async function (codigoSpot, nombreSpot, source, fechaFin) {
  return api.put('/spot/editarNoti', { codigoSpot, nombreSpot, source, fechaFin }
  ).then(res => res.data)
}

spotService.bajaSpot = async function (codigoSpot) {
  return api.delete(`/spot/del/${codigoSpot}`
  ).then(res => res.data)
}

// Programacion spot
spotService.getProgramacionesByCliente = async function () {
  return api.get('/spot/programacion/').then(res => res.data)
}
// Programacion spot
spotService.ReemplazarPautados = async function (codigoSpotIn, codigoSpot, codigoProg = 0) {
  return api.delete(`/spot/pautados/${codigoSpotIn}/${codigoSpot}/${codigoProg}`).then(res => res.data)
}
spotService.BorrarPautados = async function (codigoSpot, codigoProg = 0) {
  return api.delete(`/spot/pautados/${codigoSpot}/${codigoProg}`).then(res => res.data)
}

// Programacion spot
spotService.getLinkShare = async function (codigoSpot) {
  return api.get(`/spot/getlinkdbx/${codigoSpot}`).then(res => res.data)
}

spotService.altaProgramacion = async function (codigoProgramacion, nombreProgramacion) {
  return api.post('/spot/programacion/', {

    codigoProgramacion, nombreProgramacion

  }).then(res => res.data)
}

spotService.bajaProgramacion = async function (codigoProgramacion) {
  return api.delete(`spot/programacion/${codigoProgramacion}`
  ).then(res => res.data)
}

export default spotService
