import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'

Vue.use(Vuex)
import clienteProgramacionSpotService from '@/services/ClienteProgramacionSpotServices'
import spotService from '@/services/SpotServices'
import sucursalService from '@/services/SucursalServices'
import userService from '@/services/UserServices'

export default new Vuex.Store({
  state: {
    clientes: null,
    loading: false,
    logeado:{
      user:null,
      token:''
    },
    limiteSucursal: true,
    playDemo: false,
    spots_pautados:[],
    spots_disponibles:[],
    codigo_programacion:null,
    usuario:'',

    sucursales:[],
    prog_spot:[],
    prog_musica:[],
    urlDemo: '',
    urlImg: '',
    infoSpot:''
  },
  mutations: {

    addSucursales(state,playload){
        state.sucursales = playload
    },
    Loading(state,valor){
        state.loading = valor
    },
    setSpotsDisponibles(state,playload){
        state.spots_disponibles = playload
    },
    setProgramacionesSpot(state,playload){
      state.spots_pautados = playload
    },
    addSpotsDisponibles(state,playload){
        state.spots_disponibles.push(playload)
    },
    editEstadoSucursales(state,playload){
      state.sucursales.find(sucu=>sucu.clisuc_nombre.toLowerCase() == playload.usuario.toLowerCase()).conectado=playload.Activ
      state.sucursales.find(sucu=>sucu.clisuc_nombre.toLowerCase() == playload.usuario.toLowerCase()).reproduced=(playload.data?.tema==undefined)?'No':playload.data?.tema
      state.sucursales.find(sucu=>sucu.clisuc_nombre.toLowerCase() == playload.usuario.toLowerCase()).avance=(playload.data?.avance==undefined)?'No':playload.data?.avance

    },
    setInfoSpot(state,playload){
      state.infoSpot = playload
    },

  },
  actions: {
    GetSucursales({commit}){
      try{
       sucursalService.getcliSucursalByCliente()
       .then((sucursales)=>{
         commit("addSucursales",sucursales)
       })
      }
      catch(error){
        console.error(error)
      }
    },
    SpotsDisponibles({commit}){
      spotService.getSpotsBycodCliente()
      .then(result=>commit("setSpotDisponibles",result))
    },
    SpotsPautados({commit}){
      clienteProgramacionSpotService.getProgramacionesByProg()
    },
   async Auth({commit},playload){
       return userService.login(playload.name,playload.password)
        .then((resp)=>{
          localStorage.token = resp.data.access_token
          localStorage.refresh_token = resp.data.refresh_token
          localStorage.user = window.atob(localStorage.token.split('.')[1])

        })
    }
  },
  getters: {
    getCliente (state) {
      return JSON.parse(localStorage.user)
    },
    getLimiteSucursal (state) {
      return state.limiteSucursal
    },
    getEstadoPlayDemo (state) {
      return state.playDemo
    },
    getUrlImg (state) {
      return state.urlImg
    },
    getUrlMedia (state) {
      return state.urlDemo
    }
  },
  modules: {
  }
})
