<template>
  <div class="hero is-mobile is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="box">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
export default {
  components: {
    FadeTransition
  },
  methods: {

  },
  mounted () {

  }
}
</script>
